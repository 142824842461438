<template>
  <b-card-code no-body title="Pending Settlement">

    <div class="row mb-1" style="margin-left: 5px;">

      <div class="col-md-4">
        <label for="">Filter by Date</label><br>
        <date-picker placeholder="Filter by Settlement Daterange" v-model="filter.daterange_filter" :value="filter.daterange_filter" range format="YYYY/MM/DD" range-separator="-" input-class="form-control"></date-picker>
      </div>

      <div class="col-md-3">
        <label for="">Filter by Status</label>
        <select class="form-control" v-model="filter.settlement_status">
          <option value="">-- All Status --</option>
          <option v-for="(item, key) in statuses" :key="key" :value="key">{{ item.label }}</option>
        </select>
      </div>

    </div>

    <div style="margin-top: 5px; margin-left: 5px; margin-bottom: 10px;" v-if="checkedItems.length > 0" class="row">
      <div class="col-md-4">
        <b-dropdown size="md" v-if="checkPermission('approve auction transaction') || checkPermission('decline auction transaction')">
            <template #button-content>
              Bulk Actions
            </template>
            <b-dropdown-item v-if="checkPermission('approve auction transaction')" @click="massAction('approve')" toggle-class="text-success">
              <feather-icon
                icon="CheckCircleIcon"
              />
              Approve
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item v-if="checkPermission('decline auction transaction')" @click="massAction('decline')" toggle-class="text-danger">
              <feather-icon
                icon="XIcon"
              />
              Decline
            </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <div class="table-responsive-sm">
      <table class="table b-table">
        <thead>
          <tr>
            <th class="pl-0 pr-0"></th>
            <th>User</th>
            <th>Price</th>
            <th>Lot</th>
            <th>Settlement Date</th>
            <th>Status Date</th>
            <th>Action</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td colspan="8" class="text-center">
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </td>
          </tr>
          <tr v-else v-for="item in result.data" v-bind:key="item.slug">
            <td class="pr-0">
              <div class="custom-control custom-checkbox">
                <input class="custom-control-input" type="checkbox" :id="item.uuid" name="check[]" v-model="checkedItems" :value="item.uuid">
                <label class="custom-control-label" :for="item.uuid"></label>
              </div>
            </td>
            <td v-if="item.user != null">
                <router-link :to="{ name: 'users.show', params: { id: item.user.id } }">
                    {{ item.user.name }}<br><small>{{ item.user.email }}</small>
                </router-link>
              </td><td v-else>-</td>
            <td>{{ item.price }}</td>
            <td>{{ item.lot }} lot</td>
            <td style="text-transform: capitalize;">
                {{ item.payment_due_date || formatTimestampToDate(item.time) }}
            </td>  
            <td>
              <span :class="'badge badge-' + statuses[calculateSettlementStatus(item.payment_due_date || formatTimestampToDate(item.time))].color">
                {{ statuses[calculateSettlementStatus(item.payment_due_date || formatTimestampToDate(item.time))].label }}
              </span>
            </td>
            <td>
                <div class="btn-group" role="group">
                <button class="btn btn-info btn-sm" @click="approve(item)" v-if="checkPermission('approve auction transaction')">
                    <feather-icon
                        icon="CheckCircleIcon"
                    />
                </button>
                <button class="btn btn-danger btn-sm" @click="decline(item)" v-if="checkPermission('decline auction transaction')" style="margin-left: 2px;">
                    <feather-icon
                        icon="XIcon"
                    />
                </button>
              </div>
            </td>
            <td>
                <b-dropdown
                    variant="flat-success"
                    size="sm"
                    v-if="checkPermission('show auctions') ||checkPermission('show auction transaction detail') "
                >
                    <template #button-content>
                    <eye-icon size="1x" class="custom-class"></eye-icon> Detail
                    </template>
                    <b-dropdown-item v-if="checkPermission('show auction transaction detail')" @click="goToDetailTransaction(item.uuid)">Detail Transaction</b-dropdown-item>
                    <b-dropdown-item v-if="checkPermission('show auctions')" @click="goToDetailAuction(item.auction.uuid)">Detail Auction</b-dropdown-item>
                </b-dropdown>
            </td>
          </tr>
          <tr v-if="result.total == 0 && !isLoading">
            <td colspan="8" class="text-center">Data is empty.</td>
          </tr>
        </tbody>
      </table>

      <div class="m-1" v-if="result.total > 0">
        <div class="row">
          <div class="col">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col">
            <pagination :data="result" @pagination-change-page="getData" :limit="4" align="right"></pagination>
          </div>
        </div>
      </div>
    </div>
  </b-card-code>
</template>

<script>
import axios from 'axios';
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BCard, BCardText, BButton, BDropdown, BDropdownItem, BSpinner, BDropdownDivider } from 'bootstrap-vue'
import { EyeIcon } from 'vue-feather-icons'
import { checkPermission, errorNotification } from '@/auth/utils'
import flatPickr from 'vue-flatpickr-component'
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import 'vue2-datepicker/index.css';

export default {
  title () {
    return `Pending Settlement`
  },
  setup() {
    return {
      checkPermission,
      errorNotification
    }
  },
  components: {
    BCard,
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BCardText,
    BCardCode,
    EyeIcon,
    flatPickr,
    BSpinner,
    DatePicker
  },
  watch: {
    filter: {
      handler: _.debounce(function() {                                                                                                                                                              
        this.getData();                                                                                                                                                                        
      }, 0),
      deep: true
    },
  },
  mounted() {
    var context = this
    this.$echo.channel('DashboardAdminUpdate').listen('.DashboardAdminUpdate', (payload) => {
      if (payload.type == 'auction-transaction') {
        context.getData(context.currentPage || 1);  
      }
    });
  },
  data() {
    return {
      currentPage: 1,
      result: {},
      filter: {
        date: null,
        settlement_status: '',
      },
      isLoading: false,
      filter: {
        daterange_filter: [],
      },
      statuses: {
        normal: {
          color: 'success',
          label: 'More than 1 days'
        },
        warning: {
          color: 'warning',
          label: 'Almost Due'
        },
        overdue: {
          color: 'danger',
          label: 'Overdue'
        },
      },
      checkedItems: []
    }
  },
  created() {
    this.getData();

    this.filter.daterange_filter = [null, null]
  },
  methods: {
    getData(page = 1) {

      // this.isLoading = true;
      this.currentPage = page;
      var queryParams = this.filter
      queryParams.page = page
      queryParams.for_approval = 1
      if (queryParams.daterange_filter[0] != null && queryParams.daterange_filter[1] != null) {
        queryParams.settlement_start_date = moment(queryParams.daterange_filter[0]).format('YYYY-MM-DD');
        queryParams.settlement_end_date = moment(queryParams.daterange_filter[1]).format('YYYY-MM-DD');
      }

      this.$http.get('/admin/auction-transactions', {
        params: queryParams
      })
      .then(response => {
        this.result = response.data.data
        // this.isLoading = false;
      })

    },
    goToDetailAuction(uuid) {
      this.$router.push({ name: 'auctions.show', params: { uuid: uuid } })
    },
    goToDetailTransaction(uuid) {
      this.$router.push({ name: 'auctions.transactions.show', params: { uuid: uuid } })
    },
    formatTimestampToDate(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    calculateSettlementStatus(date) {
      var settlementDate = moment(date);
      var now = moment();
      
      if (now.isSameOrAfter(settlementDate)) {
        return 'overdue';
      } else if (now.isBefore(settlementDate) && settlementDate.diff(now, 'days', true) <= 1) {
        return 'warning';
      } else {
        return 'normal';
      }
    },
    massAction(type) {
      if (this.checkedItems.length > 0) {
        this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to ' + type + ' this?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          
          this.isLoading = true;
          this.$http.post('/admin/auction-transactions/pending-multiple-action/', {
            uuids: this.checkedItems,
            type: type
          })
          .then(response => {

            this.getData(this.currentPage)
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: 'Action success',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.getData();
            this.checkedItems = [];
            this.isLoading = true;

          })
          .catch(error => {
            if (error.response.data.meta.messages.length > 0) {
              errorNotification(this, 'Oops!', error.response.data.meta.messages)   
            }
            this.getData();
            this.isLoading = false;
          })

        }
      })
      } else {
        alert('Empty items');
      }
    },
    approve(item) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to approve this?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          
          this.$http.post('/admin/auction-transactions/' + item.uuid + '/approve')
          .then(response => {

            this.getData(this.currentPage)
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: type + ' success',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })

          })
          .catch(error => {
            if (error.response.data.meta.messages.length > 0) {
              errorNotification(this, 'Oops!', error.response.data.meta.messages)   
            }
          })

        }
      })

    },
    decline(item) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to decline this?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          
          this.$http.post('/admin/auction-transactions/' + item.uuid + '/decline')
          .then(response => {

            this.getData(this.currentPage)
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: 'Action success',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })

          })
          .catch(error => {
            if (error.response.data.meta.messages.length > 0) {
              errorNotification(this, 'Oops!', error.response.data.meta.messages)   
            }
          })

        }
      })

    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
